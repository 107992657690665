@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

* {
  font-family: 'Source Code Pro';
}

h1 {
  margin-top: -15px;
}

p {
  font-weight: 600;
  color: rgb(100, 100, 100);
}

a {
  margin: 0;
  color: black;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.relative {
  width: 100%;
  height: 100%;
  position: relative;
}

#header {
  width: calc(50% - 100px);
  height: calc(100% - 150px);
  position: fixed;
  top: 0;
  left: 0;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 100px;
  padding-bottom: 50px;
}

#portrait {
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

#contact {
  position: absolute;
  bottom: 0;
}

#contact-header {
  position: relative;
  top: 10px;
}

#contact-body {
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
}

#body {
  width: calc(50% - 100px);
  position: absolute;
  left: 50%;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 100px;
  padding-bottom: 50px;
}

.project:hover {
  cursor: pointer;
}

#project-image {
  width: 100%;
  aspect-ratio: 1.8;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0px 0px 3px black;
}

#project-text {
  margin-top: -10px;
  margin-bottom: 60px;
}

.skill-box {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  right: 5px;
  bottom: 5px;
}

#skill {
  width: 100px;
  height: 125px;
  margin-top: 5px;
  margin-left: 5px;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: rgb(100, 100, 100);
  box-shadow: 0px 0px 4px black;
  transition: 0.25s;
}

#skill:hover {
  box-shadow: 0px 0px 10px black;
  cursor: pointer;
}

#skill-image {
  width: 80px;
  height: 80px;
  margin-left: 10px;
  margin-top: 10px;
  object-fit: contain;
  filter: saturate(0);
  transition: 0.25s;
}

#skill:hover #skill-image {
  width: 90px;
  height: 90px;
  margin-left: 5px;
  margin-top: 5px;
  filter: saturate(1);
}

#skill-title {
  margin-top: -5px;
  text-align: center;
}

.small {
  font-size: 14px;
  line-height: 12px;
  padding-left: 5px;
  padding-right: 5px;
}

@media (max-width: 768px) 
{
  #header {
    width: calc(100% - 100px);
    height: auto;
    position: static;
  }

  #contact {
    position: static;
  }

  #body {
    width: calc(100% - 100px);
    position: static;
    padding-top: 25px;
  }
}